<template>
  <div class="container">
    <div class="form_container" v-if="!skipForm && !verificationStep && !forgotPassword">
      <img src="../assets/logo.png" alt="TKL" class="form_logo"/>
      <form class="form">
        <input
          type="email"
          class="form_input"
          :placeholder="$t('common.email')"
          name="email"
          v-model="email"
          autocomplete="email"
          required
          autofocus
        />
        <input
          type="password"
          class="form_input"
          v-model="password"
          :placeholder="$t('common.password')"
          autocomplete="current-password"
          required
        />
      </form>
      <button class="form_btn" @click="login">{{  $t("common.login") }}</button>
      <google-auth-sign-in class="google-signin"/>
      <p class="form_error" v-if="error">
        {{ $t(`registration.errors.${error}`) }}
      </p>
      <button class="link" @click="openForgotPassword">{{ $t('registration.forgot_password') }}</button>
    </div>
    <ForgotPassword v-else-if="forgotPassword" @close="forgotPassword = false"/>
    <AccountVerification v-else/>
  </div>
</template>

<script>
import axios from "axios";
import AccountVerification from "@/components/AccountVerification.vue";
import ForgotPassword from "../components/ForgotPassword.vue";
import { mapState } from "vuex";
export default {
  props: {
    isComponent: {
      type: Boolean,
      default: false,
    },
    skipForm: {
      type: Boolean,
      default: false,
    },
    showRegistrationModal: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    AccountVerification,
    ForgotPassword,
  },
  computed: {
    ...mapState(["isLoggedIn", "user"]),
    verified() {
      return this.user && this.user.Verified;
    },
  },
  data() {
    return {
      password: "",
      email: "",
      error: "",
      verificationStep: false,
      forgotPassword: false,
    };
  },
  created() {
    if (this.isLoggedIn) this.authenticateUser();
  },
  methods: {
    login() {
      this.error = "";
      axios
        .post(this.$apiUrl + "/auth/login", {
          Email: this.email,
          password: this.password,
        })
        .then((res) => {
          this.$store.dispatch('login', res.data.token)
          this.authenticateUser();
        })
        .catch((err) => {
          this.error = err.response.data
        })
    },
    authenticateUser(){
      this.clearForm();
      if(!this.verified){
        this.verificationStep = true;
        return;
      } 
      else if(!this.isComponent) {
        this.$router.push({ name: `${this.verificationStep ? 'verification' : 'home'}` });
      }
      this.verificationStep = false;  
    },
    clearForm() {
      this.error = "";
      this.password = "";
      this.email = "";
    },
    openForgotPassword() {
      this.forgotPassword = true;
    },
  },
  watch: {
    isLoggedIn(val) {
      if (val) this.authenticateUser();
    },
    verified(val) {
      if (val) this.authenticateUser();
    },
  }
};
</script>

<style lang="scss">
@import "@/theme.scss";

.container {
  width: 40vw;
  min-width: 500px;
}

.form_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form_input {
  border: 0;
  padding: 7px;
  border-bottom: 1px solid #ccc;
  width: 30vw;
  height: 20px;
  margin-top: 15px;
}

.form_logo {
  margin-top: 5%;
  width: 20vw;
}

.form_btn{
  @extend %secondary-pattern;
  cursor: pointer;
  display: block;
  padding: 10px;
  width: 30vw;
  margin-top: 30px;
  border: 0px solid black;
  border-radius: 5px;
  box-shadow: 0px 0px 3px 0px;
  font-size: 18px;
  color: white;
}

.form_selector {
  margin-top: 10px;
  display: flex;
  width: 30vw;
}

.form_select {
  height: 45px;
}

.form_error {
  margin-top: 10px;
  text-align:center; 
  color:red; 
  font-weight:bold;
}

.google-signin {
  margin-top: 30px;
  margin-bottom: 10px !important;
}

.link{
  background: none;
  border: none;
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
  margin-bottom: 10px;
}

@media only screen and (max-width: 600px) {

  .container {
    width: 80vw;
    min-width: initial;
  }

  .form_input {
    width: 60vw;
    font-size: 14px;
  }

  .form_logo {
    margin-top: 5%;
    width: 50vw;
  }

  .form_btn {
    width: 60vw;
    font-size: 14px;
  }

  .form_selector {
    width: 60vw;
  }

  .form_error {
    font-size: 14px;
  }

  .link{
    font-size: 14px;
  }
}
</style>
