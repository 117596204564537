<template>
  <Card>
    <template #header>
      <h2 class="header">{{ $t("common.success") }}</h2>
    </template>
    <template #body>
      <p class="text">{{ $t("registration.thank_you") }}</p>
      <p class="text">{{ $t("registration.confirmation_email", {email: user.Email}) }}</p>
      <p class="text">{{ $t("registration.confirmation_email_2") }}</p>
      <div class="code_container">
        <input type="text" class="code_input" placeholder="Verification Code" v-model="verificationCode"/>
        <button class="code_btn" :disabled="!verificationCode" @click="verifyCode()">{{ $t("common.verify") }}</button>
      </div>
      <p v-if="verification_error" class="text error">{{ $t(`registration.errors.${verification_error}`) }}</p>
    </template>
    <template #footer>
      <button v-if="!buttonDisabled" class="card-btn" @click="sendEmail">{{ $t("registration.email_not_received") }}</button>
      <p class="text center" v-else>{{ $t("registration.email_sent") }}</p>
    </template>
  </Card>
</template>

<script>
import axios from "axios";
import Card from "@/components/Card.vue";
import { mapState } from "vuex";

export default {
  components: {
    Card,
  },
  props: {
    isRegister: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(["user"]),
  },
  data() {
    return {
      verificationCode: "",
      verification_error: "",
      buttonDisabled: true,
    };
  },
  created() {
    setTimeout(() => this.enableButton(), 10000);
    if(!this.isRegister) this.sendEmail();
  },
  methods: {
    verifyCode() {
      this.verification_error = "";
      axios.post(this.$apiUrl + "/auth/verify/" + this.user._id, {code: this.verificationCode})
      .then((res) => {
        this.$store.dispatch('login', res.data.token)
      })
      .catch((err) => {
        this.verification_error = err.response.data;
      });
    },
    async sendEmail() {
      this.buttonDisabled = true;
      await axios.post(`${this.$apiUrl}/auth/send-verification/${this.user._id}`);
      setTimeout(() => this.enableButton(), 5000);
    },
    enableButton() {
      this.buttonDisabled = false;
    },
  }
}
</script>

<style lang="scss">
@import "@/theme.scss";

.code_container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.code_input {
  border-radius: 3px;
  border: 1px solid #ccc;
  padding: 10px;
}

.code_btn {
  @extend %secondary-pattern;
  cursor: pointer;
  margin-left: 10px;
  width: 20vw;
  padding: 10px;
  border: 0px solid black;
  border-radius: 5px;
  box-shadow: 0px 0px 3px 0px;
  font-size: 18px;
  color: white;
}

button:disabled{
  cursor: not-allowed;
  filter: opacity(0.7);
}

.error {
  margin-top: 10px;
  text-align:center; 
  color:red; 
  font-weight:bold;
}

.header {
  @extend %primary-pattern;
  position: relative;
  text-align: center;
  width: 100%;
  padding: 10px 0;
}

.text {
  font-size: 16px;
  margin: 10px 0;
}

.center {
  text-align: center;
}

@media only screen and (max-width: 600px) {

  .text {
    font-size: 14px;
  }

  .error {
    font-size: 14px;
  }

  .code_input {
    width: 40vw;
    font-size: 14px;
  }

  .code_btn {
    width: 40vw;
    font-size: 14px;
  }

  .card-btn {
    font-size: 14px;
  }
}
</style>
