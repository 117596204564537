<template>
  <Card>
    <template #header>
      <h2 class="header">{{ $t("registration.reset_password") }}</h2>
    </template>
    <template #body>
      <p class="text">{{ $t("registration.forgot_password_email", {email: email}) }}</p>
      <div class="input_container">
        <input type="text" class="email_input" @input="clearForm(false)" :placeholder="$t('common.email')" v-model="email"/>
        <button class="email_btn" :disabled="!email || email_sent" @click="sendEmail()">{{ $t("registration.send_email") }}</button>
      </div>
      <div class="form_container" v-if="email_sent">
        <form class="form">
          <input 
            type="text" 
            class="form_input" 
            :placeholder="$t('registration.verification_code')" 
            name="Verification Code"
            v-model="verificationCode"
            autocomplete="new-password"
            required
            autofocus
            />
          <input
            type="password"
            class="form_input"
            :placeholder="$t('common.password')"
            name="Password"
            v-model="password"
            autocomplete="new-password"
            required
            autofocus
          />
          <input
            type="password"
            class="form_input"
            :placeholder="$t('common.confirm_password')"
            name="Password Confirmation"
            v-model="confirm_password"
            autocomplete="new-password"
            required
            autofocus
          />
        </form>
        <button 
          class="form_btn" 
          @click="resetPassword" 
          :disabled="!verificationCode || !password || !confirm_password"
          >
          {{  $t("registration.reset_password") }}
        </button>
      </div>
      <p v-for="(error, id) in errors" v-bind:key="id" class="text error">{{ $t(`registration.errors.${error}`) }}</p>
    </template>
    <template #footer v-if="email_sent">
      <button v-if="!buttonDisabled" class="card-btn" @click="sendEmail">{{ $t("registration.email_not_received") }}</button>
      <p class="text center" v-else>{{ $t("registration.email_sent") }}</p>
    </template>
  </Card>
</template>

<script>
import axios from "axios";
import Card from "@/components/Card.vue";

export default {
  components: {
    Card,
  },
  data() {
    return {
      verificationCode: "",
      errors: [],
      buttonDisabled: true,
      email_sent: false,
      email: "",
      password: "",
      confirm_password: "",
    };
  },
  created() {
    setTimeout(() => this.enableButton(), 10000);
  },
  methods: {
    resetPassword() {
      this.errors = [];
      if (this.password.length < 8) this.errors.push("pass_length");
      if (this.password != this.confirm_password) this.errors.push("pass_match");
      if (this.errors.length > 0) return;
      axios.post(this.$apiUrl + "/auth/reset-password", {email: this.email, code: this.verificationCode, password: this.password})
      .then(() => {
        this.clearForm();
        this.$emit("close");
      })
      .catch((err) => {
        this.errors.push(err.response.data);
      });
    },
    clearForm(clearEmail = true) {
      if(clearEmail) this.email = "";
      this.errors = [];
      this.email_sent = false;
      this.password = "";
      this.confirm_password = "";
      this.verificationCode = "";
    },
    async sendEmail() {
      this.clearForm(false)
      this.buttonDisabled = true;
      await axios.post(this.$apiUrl + "/auth/forgot-password", {email: this.email})
      .then(() => {
        this.email_sent = true;
      })
      .catch((err) => {
        this.errors.push(err.response.data);
      });
      setTimeout(() => this.enableButton(), 5000);
    },
    enableButton() {
      this.buttonDisabled = false;
    },
  }
}
</script>

<style lang="scss">
@import "@/theme.scss";

.input_container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.email_input {
  border-radius: 3px;
  border: 1px solid #ccc;
  padding: 10px;
  width: 20vw;
}

.email_btn {
  @extend %secondary-pattern;
  cursor: pointer;
  margin-left: 10px;
  width: 15vw;
  padding: 10px;
  border: 0px solid black;
  border-radius: 5px;
  box-shadow: 0px 0px 3px 0px;
  font-size: 18px;
  color: white;
}

button:disabled{
  cursor: not-allowed;
  filter: opacity(0.7);
}

.error {
  margin-top: 10px;
  text-align:center; 
  color:red; 
  font-weight:bold;
}

.header {
  @extend %primary-pattern;
  position: relative;
  text-align: center;
  width: 100%;
  padding: 10px 0;
}

.text {
  font-size: 14px;
  margin: 10px 0;
}

.center {
  text-align: center;
}

.form_btn{
  @extend %secondary-pattern;
  cursor: pointer;
  display: block;
  padding: 10px;
  width: 30vw;
  margin-top: 30px;
  border: 0px solid black;
  border-radius: 5px;
  box-shadow: 0px 0px 3px 0px;
  font-size: 18px;
  color: white;
}

@media only screen and (max-width: 600px) {

  .text {
    font-size: 14px;
  }

  .error {
    font-size: 14px;
  }

  .email_input {
    width: 40vw;
    font-size: 14px;
  }

  .email_btn {
    width: 40vw;
    font-size: 14px;
  }

  .card-btn {
    font-size: 14px;
  }

  .form_btn {
    width: 60vw;
    font-size: 14px;
  }
}
</style>
